button, input, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

button::-moz-focus-inner {
  border: none;
}

button:disabled {
  cursor: not-allowed;
}

@font-face {
  font-display: swap;
  font-family: Mona Sans;
  font-weight: 200 900;
  src: url("Mona-Sans.f88757f1.woff2") format("woff2-variations");
}

*, :before, :after {
  transition-duration: var(--time-0);
  transition-timing-function: var(--easing-standard);
}

html {
  --mesh-0-x: 6%;
  --mesh-0-y: 84%;
  --mesh-1-x: 38%;
  --mesh-1-y: 95%;
  --mesh-2-x: 54%;
  --mesh-2-y: 3%;
  --mesh-3-x: 59%;
  --mesh-3-y: 91%;
  --mesh-4-x: 41%;
  --mesh-4-y: 56%;
  --mesh-5-x: 98%;
  --mesh-5-y: 2%;
  --mesh-6-x: 72%;
  --mesh-6-y: 62%;
  border-color: var(--color-figure-more);
  color: var(--color-figure);
  font-family: Mona Sans, sans-serif;
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height);
  min-height: 100%;
  transition-property: background-color, color;
}

html:before, html:after {
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  top: var(--header-height);
  z-index: var(--z-index--1);
  position: fixed;
}

@media (width >= 66rem) {
  html:before, html:after {
    left: var(--nav-width);
  }
}

html:before {
  --dim: var(--space-3);
  --dif: calc(.5 * var(--dim));
  --rad: radial-gradient(closest-side, #999, #000 67%);
  background: var(--rad) 0 0 / var(--dim) var(--dim), var(--rad) var(--dif) var(--dif) / var(--dim) var(--dim), linear-gradient(.25turn, #000, #888, #000);
  background-blend-mode: screen, difference;
  filter: contrast(20);
}

html:after {
  background: repeating-radial-gradient(circle, var(--color-ground), var(--color-theme-less), var(--color-ground) 50%);
  background: radial-gradient(circle, var(--color-theme-less), var(--color-theme-less) 10%, var(--color-ground) 49.75%, var(--color-theme-less) 50%, var(--color-ground) 100%);
  mix-blend-mode: lighten;
}

@media (prefers-color-scheme: dark) {
  html:before {
    mix-blend-mode: lighten;
    filter: contrast(6);
    background: repeating-linear-gradient(.125turn, #000, #666, #000 10%), repeating-radial-gradient(circle, #eee, #000, #eee 2%), repeating-conic-gradient(#eee, #000, #eee 10%);
  }

  html:after {
    background-image: radial-gradient(at var(--mesh-0-x) var(--mesh-0-y), var(--color-highlight-0) 0, transparent 50%), radial-gradient(at var(--mesh-1-x) var(--mesh-1-y), var(--color-highlight-1) 0, transparent 50%), radial-gradient(at var(--mesh-2-x) var(--mesh-2-y), var(--color-highlight-2) 0, transparent 50%), radial-gradient(at var(--mesh-3-x) var(--mesh-3-y), var(--color-highlight-3) 0, transparent 50%), radial-gradient(at var(--mesh-4-x) var(--mesh-4-y), var(--color-highlight-4) 0, transparent 50%), radial-gradient(at var(--mesh-5-x) var(--mesh-5-y), var(--color-highlight-5) 0, transparent 50%), radial-gradient(at var(--mesh-6-x) var(--mesh-6-y), var(--color-theme-less) 0, transparent 50%);
    background-color: var(--color-ground);
    mix-blend-mode: darken;
  }
}

::selection {
  background-color: var(--color-highlight-default);
  color: var(--color-figure-more);
}

body {
  font-size: var(--font-size-1);
  margin: 0;
}

main {
  margin-bottom: var(--quick-nav-height);
  justify-content: center;
  display: flex;
}

@media (width >= 66rem) {
  main {
    padding-left: var(--nav-width);
  }
}

a {
  color: var(--color-theme);
  transition-property: filter, text-decoration-color;
}

a, button {
  outline-color: var(--color-highlight-default);
}

button {
  transition-property: box-shadow, filter, opacity, scale;
}

button:disabled:not(.pagination__button) {
  filter: grayscale(.5);
  opacity: .75;
}

@media (hover: hover) {
  :where(a, button:not(:disabled):not(:active)):hover {
    filter: brightness(1.25) saturate(1.25) contrast(1.25);
  }

  a:hover {
    text-decoration-color: #0000;
  }
}

:where(a, button:not(:disabled)):active {
  filter: brightness(.9) saturate(.9) contrast(1.5);
}

a:active {
  text-decoration-color: #0000;
}

a::-moz-focus-inner {
  border: none;
}

blockquote {
  margin: var(--space-3);
  font-style: italic;
}

blockquote footer {
  font-weight: var(--font-weight-bold);
}

cite {
  font-style: normal;
}

fieldset {
  border-color: var(--color-balance);
  border-radius: var(--border-radius-1);
  border-width: var(--border-width-1);
  margin: var(--space-3) 0;
  padding: var(--space-1) var(--space-2);
  -webkit-user-select: none;
  user-select: none;
}

legend {
  font-size: var(--font-size-0);
  font-weight: var(--font-weight-bold);
}

ol, ul {
  margin: var(--space-1) 0 var(--space-2);
  padding-left: var(--space-3);
}

li {
  margin: var(--space-0) 0;
}

h1, h2, h3, h4 {
  color: var(--color-figure-more);
  font-weight: var(--font-weight-bold);
  margin: var(--space-3) 0 var(--space-0);
  transition-property: color;
}

h1 {
  font-size: var(--font-size-4);
  text-align: center;
}

h2 {
  font-size: var(--font-size-3);
}

h3 {
  font-size: var(--font-size-2);
}

h4 {
  font-size: var(--font-size-1);
}

h1 + h2, h2 + h3, h3 + h4 {
  margin-top: 0;
}

hr {
  border-bottom: none;
  border-color: var(--color-balance);
  border-inline: none;
  border-width: var(--border-width-2);
  margin: var(--space-3) 0;
}

p {
  --font-margin-paragraph: var(--space-2) 0;
  margin: var(--font-margin-paragraph);
  margin-top: 0;
}

p + p {
  margin: var(--font-margin-paragraph);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: .75;
  transition-property: opacity;
}

@media (prefers-color-scheme: dark) {
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(.8);
  }
}

@media (hover: hover) {
  input[type="date"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1;
  }
}

:where(input:not([type="checkbox"]), select, textarea) {
  background-color: var(--color-ground-more);
  border-radius: var(--border-radius-1);
  border: var(--border-width-2) solid var(--color-figure-more);
  padding: var(--space-1);
  outline: 0;
  transition-property: background-color, border, box-shadow;
}

:where(input:not([type="checkbox"]), select, textarea):focus {
  border-color: var(--color-theme-less);
  box-shadow: var(--box-shadow-input-focus);
}

@media (hover: hover) {
  :where(input:not([type="checkbox"]), select, textarea):hover {
    border-color: var(--color-theme-less);
  }
}

:where(input:not([type="checkbox"]), select, textarea)[aria-invalid="true"] {
  border-color: var(--color-negative);
  box-shadow: none;
}

small {
  color: var(--color-figure-less);
  font-size: var(--font-size-0);
}

b, strong {
  color: var(--color-figure-more);
  font-weight: var(--font-weight-bold);
}

img {
  border-radius: var(--border-radius-1);
  margin: var(--space-3) auto;
  max-width: 80%;
  display: block;
}

table {
  border-spacing: 0;
  margin: var(--space-2) auto;
  text-align: end;
}

td, th {
  padding: var(--space-0) calc(var(--space-3) / 2);
}

th {
  border-bottom: var(--border-width-2) solid var(--color-figure-more);
  font-weight: var(--font-weight-bold);
}

:where(td, th):first-child {
  text-align: start;
}

tr:not(:last-child) td {
  border-bottom: var(--border-width-0) solid var(--color-figure-more);
}

dialog {
  animation-duration: var(--time-1);
  animation-name: fade-in, scale-in;
  animation-timing-function: var(--easing-decelerate);
  background-color: var(--color-ground);
  color: inherit;
  max-height: calc(100vh - 2 * var(--space-3));
  max-width: min(var(--break-point), calc(100vw - 2 * var(--space-3)));
  border: none;
}

dialog[data-closing] {
  animation-name: fade-out, scale-out;
}

dialog::backdrop {
  animation-duration: var(--time-1);
  animation-name: fade-in;
  animation-timing-function: var(--easing-decelerate);
  backdrop-filter: blur(var(--space-0));
  background: var(--lightningcss-light, linear-gradient(var(--linear-gradient-angle), #1d2630bf, #131a20bf)) var(--lightningcss-dark, linear-gradient(var(--linear-gradient-angle), #304050bf, #263340bf));
}

dialog[data-closing]::backdrop {
  animation-name: fade-out;
}

:root {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light dark;
  --color-theme: var(--lightningcss-light, #053361) var(--lightningcss-dark, #0d80f2);
  --color-min: var(--lightningcss-light, hsl(from var(--color-theme) h 25% 5%)) var(--lightningcss-dark, hsl(from var(--color-theme) h 50% 90%));
  --color-max: var(--lightningcss-light, hsl(from var(--color-min) h s 100%)) var(--lightningcss-dark, hsl(from var(--color-min) h s 1%));
  --color-figure-more: var(--color-min);
  --color-figure: color-mix(in oklab, var(--color-min), var(--color-max) 13%);
  --color-figure-less: color-mix(in oklab, var(--color-min), var(--color-max) 26%);
  --color-ground-more: var(--lightningcss-light, var(--color-max)) var(--lightningcss-dark, color-mix(in oklab, var(--color-max), var(--color-min) 14%));
  --color-ground: var(--lightningcss-light, color-mix(in oklab, var(--color-max), var(--color-min) 5%)) var(--lightningcss-dark, color-mix(in oklab, var(--color-max), var(--color-min) 7%));
  --color-ground-less: var(--lightningcss-light, color-mix(in oklab, var(--color-max), var(--color-min) 10%)) var(--lightningcss-dark, var(--color-max));
  --color-balance: color-mix(in oklab, var(--color-min), var(--color-max));
  --color-theme-app: color-mix(in hsl, var(--color-theme), var(--color-min) 30%);
  --color-theme-less: color-mix(in hsl, var(--color-theme), var(--color-max) 15%);
  --color-negative: var(--lightningcss-light, #da0b3f) var(--lightningcss-dark, #f000a0);
  --color-positive: #1cc470;
  --color-highlight-0: var(--lightningcss-light, #00e4f5) var(--lightningcss-dark, #0ef);
  --color-highlight-1: var(--lightningcss-light, #ab57ff) var(--lightningcss-dark, #b366ff);
  --color-highlight-2: var(--lightningcss-light, #f655f6) var(--lightningcss-dark, #ff5cff);
  --color-highlight-3: var(--lightningcss-light, #ff941a) var(--lightningcss-dark, #ffa947);
  --color-highlight-4: var(--lightningcss-light, #bfe600) var(--lightningcss-dark, #d4ff00);
  --color-highlight-5: var(--lightningcss-light, #00e66b) var(--lightningcss-dark, #00e660);
  --color-highlight-default: var(--lightningcss-light, var(--color-highlight-3)) var(--lightningcss-dark, var(--color-highlight-2));
  --color-mask: var(--lightningcss-light, color-mix(in oklab, var(--color-min), #0000 25%)) var(--lightningcss-dark, color-mix(in oklab, var(--ground), #0000 50%));
  --color: var(--color-theme);
  --linear-gradient-angle: calc(3turn / 32);
  --color-gradient: linear-gradient(var(--linear-gradient-angle), color-mix(in hsl, var(--color), var(--color-min) 20%), color-mix(in hsl, var(--color), var(--color-max) 20%));
}

@media (prefers-color-scheme: dark) {
  :root {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
  }
}

:root {
  --easing-accelerate: cubic-bezier(.4, 0, 1, 1);
  --easing-decelerate: cubic-bezier(0, 0, .2, 1);
  --easing-standard: cubic-bezier(.4, 0, .2, 1);
  --time-base-value: .1s;
  --time-scale-ratio: 2.5;
  --time-0: var(--time-base-value);
  --time-1: calc(var(--time-0) * var(--time-scale-ratio));
  --time-2: calc(var(--time-1) * var(--time-scale-ratio));
  --time-3: calc(var(--time-2) * var(--time-scale-ratio));
}

@media (width >= 38rem) {
  :root {
    --time-0: .11s;
  }
}

@media (width >= 66rem) {
  :root {
    --time-0: .12s;
  }
}

:root {
  --animation-fade-in: fade-in var(--time-2) var(--easing-standard) forwards;
  --animation-slide-in: var(--animation-fade-in), slide-in var(--time-1) var(--easing-decelerate) forwards;
  --animation-spin: rotate var(--time-2) linear infinite;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes rotate {
  to {
    rotate: 1turn;
  }
}

@keyframes scale-in {
  from {
    scale: .9;
  }

  to {
    scale: 1;
  }
}

@keyframes scale-out {
  from {
    scale: 1;
  }

  to {
    scale: .9;
  }
}

@keyframes slide-in {
  from {
    translate: 0 25vh;
  }
}

.fade-in {
  animation: var(--animation-fade-in);
  opacity: 0;
}

.slide-in > * {
  animation: var(--animation-slide-in);
}

.slide-children-in > * {
  animation: var(--animation-slide-in);
  opacity: 0;
}

.slide-children-in > :nth-child(2) {
  animation-delay: var(--time-0);
}

.slide-children-in > :nth-child(3) {
  animation-delay: calc(var(--time-0) * 2);
}

.slide-children-in > :nth-child(4) {
  animation-delay: calc(var(--time-0) * 3);
}

.slide-children-in > :nth-child(5) {
  animation-delay: calc(var(--time-0) * 4);
}

.slide-children-in > :nth-child(6) {
  animation-delay: calc(var(--time-0) * 5);
}

.slide-children-in > :nth-child(7) {
  animation-delay: calc(var(--time-0) * 6);
}

.slide-children-in > :nth-child(8) {
  animation-delay: calc(var(--time-0) * 7);
}

.slide-children-in > :nth-child(9) {
  animation-delay: calc(var(--time-0) * 8);
}

.slide-children-in > :nth-child(10) {
  animation-delay: calc(var(--time-0) * 9);
}

.slide-children-in > :nth-child(11) {
  animation-delay: calc(var(--time-0) * 10);
}

.slide-children-in > :nth-child(12) {
  animation-delay: calc(var(--time-0) * 11);
}

:root {
  --border-radius-0: .3em;
  --border-radius-1: .5em;
  --border-width-0: 1px;
  --border-width-1: calc(var(--border-width-0) * 2);
  --border-width-2: calc(var(--border-width-0) * 3);
}

.br-0 {
  border-radius: var(--border-radius-0);
}

.br-1 {
  border-radius: var(--border-radius-1);
}

.br-max {
  border-radius: 100em;
}

.bw-0 {
  border-width: var(--border-width-0);
  border-style: solid;
}

.bw-1 {
  border-width: var(--border-width-1);
  border-style: solid;
}

.bw-2 {
  border-width: var(--border-width-2);
  border-style: solid;
}

:root {
  --blur-radius-0: 4px;
  --blur-radius-1: 5px;
  --blur-radius-2: 6px;
  --color-box-shadow: var(--lightningcss-light, var(--color-figure)) var(--lightningcss-dark, var(--color-ground-less));
  --box-shadow-even-0-no-color: 0 0 var(--blur-radius-0);
  --box-shadow-even-1-no-color: 0 0 var(--blur-radius-1);
  --box-shadow-even-2-no-color: 0 0 var(--blur-radius-2);
  --box-shadow-0: 0 2px var(--blur-radius-0) hsla(from var(--color-box-shadow) h s l / .4);
  --box-shadow-1: 0 3px var(--blur-radius-1) hsla(from var(--color-box-shadow) h s l / .6);
  --box-shadow-2: 0 4px var(--blur-radius-2) hsla(from var(--color-box-shadow) h s l / .8);
  --box-shadow-input-focus: var(--box-shadow-even-0-no-color) var(--color-theme-less);
}

.bs-0 {
  box-shadow: var(--box-shadow-0);
}

.bs-1 {
  box-shadow: var(--box-shadow-1);
}

a, .ripple {
  position: relative;
  overflow: hidden;
}

.ripple:after {
  background-image: radial-gradient(circle, var(--color) 10%, transparent 10%);
  content: "";
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--time-2) var(--easing-standard), scale var(--time-1) var(--easing-standard);
  display: block;
  position: absolute;
  inset: 0;
  scale: 10;
}

.ripple:active:after {
  opacity: .25;
  transition: all;
  scale: 0;
}

.negative {
  color: var(--color-negative);
}

.positive {
  color: var(--color-positive);
}

:root {
  --break-point: 100vw;
  --header-height: 3.93em;
  --nav-width: 17rem;
  --quick-nav-height: 0;
  --space-scale-ratio: 1.61803;
  --space-0: calc(1em / 3);
  --space-1: calc(var(--space-0) * var(--space-scale-ratio));
  --space-2: calc(var(--space-1) * var(--space-scale-ratio));
  --space-3: calc(var(--space-2) * var(--space-scale-ratio));
  --space-4: calc(var(--space-3) * var(--space-scale-ratio));
}

@media (width >= 38rem) {
  :root {
    --break-point: 38rem;
  }
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: var(--space-0);
}

.p-2 {
  padding: var(--space-1);
}

.p-3 {
  padding: var(--space-2);
}

.p-4 {
  padding: var(--space-3);
}

.center {
  text-align: center;
}

:root {
  --font-size-scale-ratio: calc(4 / 3);
  --font-size-0: calc(.72rem + .2vmin);
  --font-size-1: calc(var(--font-size-0) * var(--font-size-scale-ratio));
  --font-size-2: calc(var(--font-size-1) * var(--font-size-scale-ratio));
  --font-size-3: calc(var(--font-size-2) * var(--font-size-scale-ratio));
  --font-size-4: calc(var(--font-size-3) * var(--font-size-scale-ratio));
  --font-weight-bold: 800;
  --font-weight-normal: 500;
  --line-height: calc(4 / 3);
}

.fs-0 {
  font-size: var(--font-size-0);
}

.fw-b {
  font-weight: var(--font-weight-bold);
}

.fw-i {
  font-weight: initial;
}

.fw-n {
  font-weight: var(--font-weight-normal);
}

.italic {
  font-style: italic;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.nowrap {
  white-space: nowrap;
}

.pre-line {
  white-space: pre-line;
}

:root {
  --z-index--1: -1;
  --z-index-1: 1;
  --z-index-2: 2;
  --z-index-3: 3;
}

.z-1 {
  z-index: var(--z-index-1);
}

.z-2 {
  z-index: var(--z-index-2);
}

.z-3 {
  z-index: var(--z-index-3);
}

.button:where(:active, :disabled, :focus) {
  box-shadow: none;
}

.button--primary {
  background: var(--color-gradient);
  color: var(--color-ground);
  padding: calc(var(--space-1)  + 2 * var(--border-width-1)) calc(var(--space-3)  + 2 * var(--border-width-1));
}

@media (hover: hover) {
  .button--primary:not(:disabled):hover {
    box-shadow: var(--box-shadow-1);
  }
}

.button--secondary {
  border-color: var(--color);
  color: var(--color);
  padding: var(--space-1) var(--space-3);
}

@media (hover: hover) {
  .button--secondary:not(:disabled):hover {
    box-shadow: var(--box-shadow-1);
  }
}

.button--tertiary {
  color: var(--color);
  border: none;
}

.button--danger {
  --color: var(--color-negative);
}

.button--danger.button--primary {
  background: linear-gradient(var(--linear-gradient-angle), color-mix(in hsl, var(--color), var(--color-min) 20%), color-mix(in hsl, var(--color), var(--color-max) 20%));
}

.button-group {
  margin: var(--space-3) 0 var(--space-4);
  flex-direction: column;
  display: flex;
}

.button-group > :not(:last-child) {
  margin-bottom: var(--space-3);
}

@media (width >= 38rem) {
  .button-group {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }

  .button-group > :not(:first-child) {
    margin-right: var(--space-3);
  }

  .button-group > :not(:last-child) {
    margin-bottom: unset;
  }
}

.card {
  background-color: var(--color-ground-more);
  border-color: var(--color);
  box-shadow: var(--box-shadow-even-1-no-color) var(--color);
  transition-property: border-color, box-shadow;
}

button.card {
  text-align: initial;
  width: 100%;
  display: block;
}

button.card:hover {
  box-shadow: var(--box-shadow-even-2-no-color) var(--color);
  scale: 1.01;
}

button.card:active {
  scale: 1;
}

.card:nth-child(6n+1) {
  --color: var(--color-highlight-0);
}

.card:nth-child(6n+2) {
  --color: var(--color-highlight-1);
}

.card:nth-child(6n+3) {
  --color: var(--color-highlight-2);
}

.card:nth-child(6n+4) {
  --color: var(--color-highlight-3);
}

.card:nth-child(6n+5) {
  --color: var(--color-highlight-4);
}

.card:nth-child(6n+6) {
  --color: var(--color-highlight-5);
}

.card-group > * {
  margin: var(--space-2) 0;
}

.column-chart {
  --axis-border: var(--color-figure) var(--border-width-1) solid;
  --grid-border: var(--border-width-0) var(--color-balance) dotted;
  margin-block: var(--space-3);
  row-gap: var(--space-1);
  -webkit-user-select: none;
  user-select: none;
  grid-template-rows: 1fr auto auto;
  grid-template-columns: 1em min-content;
  min-height: 18em;
  display: grid;
}

.column-chart__column {
  border-bottom: var(--axis-border);
  border-right: var(--grid-border);
  box-sizing: border-box;
  grid-column-start: calc(var(--column-number)  + 3);
  pointer-events: none;
  transform-origin: bottom;
  grid-row: 1;
  position: relative;
}

.column-chart__column:after {
  animation-delay: calc(var(--column-number) / max((var(--column-count)  - 1), 1) * var(--time-1));
  animation-duration: var(--time-2);
  content: "";
  opacity: calc(min(var(--column-height), 1%) * 100);
  pointer-events: auto;
  transform-origin: bottom;
  transition: scale var(--time-1);
  background: currentColor;
  animation-name: column-chart__grow-column-y;
  animation-fill-mode: forwards;
  display: block;
  position: absolute;
  inset: 0 10%;
  scale: 100% 0%;
}

.column-chart__column--clickable {
  cursor: pointer;
  transition-property: filter;
}

.column-chart__column--clickable:hover:not(:active) {
  filter: brightness(1.15) contrast(1.15) saturate(1.15);
}

.column-chart__grid-lines {
  border-bottom: var(--axis-border);
  border-left: var(--axis-border);
  grid-column-end: span var(--column-count);
  flex-direction: column;
  grid-row-start: 1;
  grid-column-start: 3;
  display: flex;
}

.column-chart__grid-lines > * {
  border-top: var(--grid-border);
  flex-grow: 1;
}

.column-chart__x-label {
  animation-delay: calc(var(--x-label-number) / max((var(--column-count)  - 1), 1) * var(--time-2));
  font-size: var(--font-size-0);
  align-self: start;
  align-items: center;
  gap: var(--space-0);
  white-space: nowrap;
  grid-row: 2;
  justify-self: center;
  display: flex;
}

.column-chart__x-label--rotate {
  writing-mode: vertical-lr;
}

.column-chart__x-label .icon {
  animation-delay: inherit;
}

.column-chart__y-axis {
  font-size: var(--font-size-0);
  padding-inline: var(--space-1);
  flex-direction: column-reverse;
  grid-row-start: 1;
  justify-content: space-between;
  place-items: end;
  display: flex;
}

.column-chart__y-label {
  animation-delay: calc(var(--y-label-number) / max((var(--y-label-count)  - 1), 1) * var(--time-2));
  translate: 0 calc(var(--y-label-number) / max((var(--y-label-count)  - 1), 1) * -100% + 50%);
}

.column-chart__x-title {
  font-weight: var(--font-weight-bold);
  grid-column: 3 / span var(--column-count);
  grid-row-start: 3;
  justify-self: center;
}

.column-chart__y-title {
  font-weight: var(--font-weight-bold);
  grid-row-start: 1;
  place-self: center;
  rotate: .75turn;
}

@keyframes column-chart__grow-column-y {
  from {
    scale: 100% 0%;
  }

  to {
    scale: 100% var(--column-height);
  }
}

.line {
  animation: draw var(--time-3) var(--easing-standard) forwards;
  stroke-dasharray: var(--length);
  stroke-dashoffset: var(--length);
}

.line-chart {
  margin-inline-end: var(--space-1);
  margin-block: var(--space-3);
  gap: var(--space-1);
  -webkit-user-select: none;
  user-select: none;
  grid-template-rows: min-content auto auto;
  grid-template-columns: 1em auto 1fr;
  display: grid;
}

.line-chart--centered {
  margin-inline-end: 0;
}

.line-chart__x-title {
  font-weight: var(--font-weight-bold);
  grid-column: 3;
  justify-self: center;
}

.line-chart__x-label {
  animation-delay: calc(var(--label-number) / max((var(--label-count)  - 1), 1) * var(--time-2));
  text-align: center;
  translate: calc(var(--label-number) / max((var(--label-count)  - 1), 1) * 100% - 50%) 0;
  flex-grow: 1;
  flex-basis: 0;
}

.line-chart__x-label--centered {
  translate: none;
}

.line-chart__y-label {
  animation-delay: calc(var(--label-number) / max((var(--label-count)  - 1), 1) * var(--time-2));
  translate: 0 calc(var(--label-number) / max((var(--label-count)  - 1), 1) * -100% + 50%);
}

.line-chart__y-title {
  font-weight: var(--font-weight-bold);
  place-self: center;
  rotate: .75turn;
}

.line-chart__plot-area {
  display: flex;
  position: relative;
}

.line-chart__svg {
  border-bottom: var(--border-width-1) solid var(--color-figure);
  border-left: var(--border-width-1) solid var(--color-figure);
}

.line-chart__point {
  --point-diameter: .275em;
  animation: point-appear var(--time-2) var(--easing-standard) forwards;
  animation-delay: calc(var(--point-index) / (var(--total-points)  - 1) * (var(--time-3)  - var(--time-2) / 2));
  height: var(--point-diameter);
  opacity: 1;
  width: var(--point-diameter);
  border-radius: 100%;
  position: absolute;
  scale: 0;
}

@keyframes point-appear {
  to {
    opacity: 1;
    scale: 1;
  }
}

.line-chart__x-axis {
  font-size: var(--font-size-0);
  grid-column: 3;
  display: flex;
}

.line-chart__y-axis {
  font-size: var(--font-size-0);
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: end;
  display: flex;
}

.checkbox {
  --checkbox-size: var(--font-size-3);
  position: relative;
}

.checkbox__label {
  column-gap: var(--space-2);
  cursor: pointer;
  grid-template-columns: auto 1fr;
  display: grid;
}

.checkbox__input {
  height: var(--checkbox-size);
  opacity: 0;
  pointer-events: none;
  width: var(--checkbox-size);
  align-self: flex-start;
  position: absolute;
  inset: 0;
}

.checkbox__appearance {
  background-color: var(--color-ground-more);
  box-sizing: border-box;
  color: var(--color-figure-more);
  height: var(--checkbox-size);
  stroke-dasharray: 30;
  stroke-dashoffset: -30px;
  width: var(--checkbox-size);
  flex-shrink: 0;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  transition-property: border, box-shadow;
  display: inline-flex;
}

.checkbox__input:focus ~ .checkbox__appearance {
  border-color: var(--color-theme);
  box-shadow: var(--box-shadow-input-focus);
}

.checkbox__input:hover ~ .checkbox__appearance {
  border-color: var(--color-theme-less);
}

.checkbox__input[aria-invalid="true"] ~ .checkbox__appearance {
  border-color: var(--color-negative);
  box-shadow: none;
}

.checkbox__input[aria-invalid="true"]:focus-visible ~ .checkbox__appearance {
  border-color: var(--color-theme);
  box-shadow: var(--box-shadow-input-focus);
}

.checkbox__input:checked ~ .checkbox__appearance {
  stroke-dashoffset: 0;
  transition-property: border, stroke-dashoffset;
}

.checkbox__label-copy {
  align-items: center;
  display: flex;
}

.field {
  margin-block: var(--space-2);
}

.field-error:not(.field-error--indent) {
  padding-top: var(--space-0);
}

.field-error--indent {
  grid-column-start: 2;
}

.icon {
  --size: 1em;
  height: var(--size);
  vertical-align: middle;
  width: var(--size);
}

.icon--draw {
  animation: draw var(--time-1) var(--easing-standard) forwards;
  stroke-dasharray: 84;
  stroke-dashoffset: -84px;
}

.icon--inline {
  position: relative;
  bottom: 2px;
}

.icon--margin-start {
  margin-inline-start: var(--space-0);
}

.icon--margin-end {
  margin-inline-end: var(--space-0);
}

.icon--size-2 {
  --size: var(--font-size-2);
}

.icon--size-3 {
  --size: var(--font-size-3);
}

.icon--size-4 {
  --size: var(--font-size-4);
}

.field-label-text {
  margin-bottom: var(--space-0);
  display: block;
}

.label {
  flex-direction: column;
  display: flex;
}

.supportive-text {
  margin-block: calc(-1 * var(--space-0)) var(--space-0);
}

.currency-field__input-container {
  flex-direction: column;
  display: flex;
  position: relative;
}

.currency-field__input-container:after {
  content: "£";
  inset-block: 0;
  left: var(--space-2);
  align-items: center;
  display: flex;
  position: absolute;
}

input.currency-field__input {
  padding-left: calc(var(--space-4)  - var(--space-0));
}

.dialog__container {
  padding: var(--space-3);
}

.dialog__close-button-container {
  margin-top: calc(var(--space-1) * -1);
}

.dialog__header {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.dialog__header > :first-child {
  margin-top: 0;
}

.dialog__content > :last-child {
  margin-bottom: 0;
}

.close-button {
  margin-left: var(--space-2);
}

@media (width >= 66rem) {
  #quick-nav-portal {
    display: none;
  }
}

.fab {
  background: var(--color-gradient);
  bottom: var(--space-2);
  box-shadow: var(--box-shadow-1);
  color: var(--color-ground);
  right: var(--space-2);
  align-items: center;
  display: flex;
  position: fixed;
}

.fab:active:not(:disabled) {
  box-shadow: var(--box-shadow-0);
}

.fab--exiting, .fab--preEnter {
  opacity: 0;
  transition-timing-function: var(--easing-accelerate);
  scale: 0;
}

.quick-nav-mounted .fab {
  display: none;
}

@media (width >= 66rem) {
  .quick-nav-mounted .fab {
    display: initial;
  }
}

header {
  background: linear-gradient(var(--linear-gradient-angle), var(--color-theme-app), var(--color-theme-less));
  color: var(--color-ground);
  height: var(--header-height);
  justify-content: center;
  display: flex;
}

header h1 {
  color: inherit;
  margin: 0;
}

header a {
  color: inherit;
  transition: text-shadow var(--time-1) var(--easing-standard);
  text-decoration: none;
}

.header__container {
  height: 100%;
  margin: 0 var(--space-2);
  width: min(calc(100vw - 2 * var(--space-2)), var(--break-point));
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (width >= 66rem) {
  header {
    z-index: var(--z-index-2);
    position: sticky;
    top: 0;
  }

  .header__container {
    margin-left: calc(var(--nav-width)  + var(--space-2));
  }
}

.image-upload__img {
  max-width: 50%;
}

.image-upload__input {
  display: inline-flex;
}

.nav {
  background: var(--color-ground-more);
  box-shadow: var(--box-shadow-2);
  box-sizing: border-box;
  overscroll-behavior: contain;
  max-width: 66.6667vw;
  padding-bottom: var(--space-2);
  transition-duration: var(--time-1);
  width: var(--nav-width);
  transition-property: translate;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

@media (width >= 66rem) {
  .nav {
    background: var(--lightningcss-light, var(--color-ground)) var(--lightningcss-dark, var(--color-ground-more));
    box-shadow: var(--box-shadow-0);
    padding-bottom: var(--space-3);
    left: 0;
    right: initial;
    top: var(--header-height);
    translate: 0 !important;
  }
}

.nav--exiting, .nav--preEnter {
  translate: var(--nav-width);
  transition-timing-function: var(--easing-accelerate);
}

.nav__close-button {
  right: var(--space-2);
  top: var(--space-1);
  position: absolute;
}

@media (width >= 66rem) {
  .nav__close-button {
    display: none;
  }
}

:root:is(:has(.mask__mask--enter-done), :has(:modal)) {
  overflow: hidden;
}

.mask__container {
  position: relative;
}

.mask__mask {
  backdrop-filter: blur(var(--space-0));
  background-color: var(--color-mask);
  opacity: 1;
  transition: opacity var(--time-1) var(--easing-decelerate);
  position: fixed;
  inset: 0;
}

.mask__mask--exiting, .mask__mask--preEnter {
  opacity: 0;
  transition-duration: var(--time-2);
}

.nav-button {
  margin-right: var(--space-0);
  transition: color var(--time-1) var(--easing-standard);
  align-items: center;
  display: flex;
}

@media (hover: hover) {
  .nav-button:hover {
    color: var(--color-highlight-default);
  }
}

@media (width >= 66rem) {
  .nav-button {
    display: none;
  }
}

.nav-link {
  list-style: none;
}

.nav-link__link {
  color: var(--color-figure);
  padding: var(--space-1) var(--space-3);
  filter: initial;
  text-decoration: none;
  transition-property: background-color, color;
  display: block;
}

.nav-link__link:focus-visible {
  color: var(--color-theme-less);
  background-color: var(--color-ground-less);
}

@media (hover: hover) {
  .nav-link__link:hover {
    background-color: var(--color-ground-less);
  }
}

.nav-sub-list__list .nav-link__link {
  padding-bottom: var(--space-0);
  padding-left: var(--space-4);
  padding-top: var(--space-0);
}

:is(.nav-link__link--active, .nav-link__link:active) {
  --color: var(--color-ground);
  color: var(--color);
  background-color: var(--color-figure);
}

.nav-link__link--active:is(:focus, :hover) {
  color: var(--color);
}

.nav-list {
  padding: var(--space-4) 0 0;
}

@media (width >= 66rem) {
  .nav-list {
    padding-top: var(--space-2);
  }
}

.nav-sub-list__list {
  margin-top: calc(-1 * var(--space-0));
  list-style: none;
}

.nav-sub-list__heading {
  --color: var(--color-figure);
  padding: var(--space-1) var(--space-3);
  outline: 0;
  justify-content: space-between;
  width: 100%;
  transition-property: background-color, color;
  display: flex;
  filter: none !important;
}

.nav-sub-list__heading:focus-visible {
  color: var(--color-theme-less);
  background-color: var(--color-ground-less);
}

@media (hover: hover) {
  .nav-sub-list__heading:hover {
    color: var(--color-theme-less);
    background-color: var(--color-ground-less);
  }
}

.nav-sub-list__icon {
  transition: rotate var(--time-1) var(--easing-standard);
}

.nav-sub-list__icon--expanded {
  rotate: -.5turn;
}

.pagination {
  border-block: var(--border-width-2) solid currentColor;
  margin-block: var(--space-2);
  padding: var(--space-1) 0;
  justify-content: center;
  display: flex;
}

.pagination__button {
  color: var(--color-theme);
  padding-block: var(--space-0);
  padding-inline: var(--space-1);
}

@media (width >= 38rem) {
  .pagination__button {
    padding-inline: var(--space-2);
  }

  .pagination__button--arrow {
    padding-inline: var(--space-0);
  }
}

@media (hover: hover) {
  .pagination__button:hover {
    text-decoration: underline;
  }
}

.pagination__button:active {
  text-decoration: underline;
}

.pagination__button:not(:last-child) {
  margin-right: var(--space-0);
}

.pagination__button:disabled {
  background: var(--color-theme);
  cursor: default;
  transition-property: filter;
  color: var(--color-ground) !important;
  text-decoration: none !important;
}

.paper {
  background: var(--color-ground-more);
  overflow: hidden;
}

@media (width >= 38rem) {
  .paper {
    border-radius: var(--border-radius-0);
  }
}

:is(.card, .paper) > :first-child:not(.spinner), :is(.card, .paper) > :first-child > :first-child:not(.spinner), :is(.card, .paper) > :first-child > :first-child > :first-child:not(.spinner) {
  margin-top: 0;
}

:is(.card, .paper) > :last-child:not(.spinner), :is(.card, .paper) > :last-child > :last-child:not(.spinner), :is(.card, .paper) > :last-child > :last-child > :last-child:not(.spinner) {
  margin-bottom: 0;
}

button.paper {
  text-align: initial;
  position: relative;
}

button.paper:before {
  background: var(--color-figure-more);
  content: "";
  opacity: 0;
  z-index: var(--z-index-1);
  position: absolute;
  inset: 0;
}

.paper-group {
  grid-gap: var(--space-2);
  padding-block: var(--space-3);
  width: 100vw;
  display: grid;
  overflow: hidden;
}

@media (width >= 38rem) {
  .paper-group {
    padding-inline: var(--space-3);
    width: var(--break-point);
  }
}

.quick-nav {
  --animation-delay: calc(var(--time-0) / 2);
  animation: animate-quick-nav-in var(--time-1) var(--easing-decelerate) forwards;
  background: var(--color-theme);
  bottom: 0;
  justify-content: space-evenly;
  display: flex;
  position: fixed;
  inset-inline: 0;
}

.quick-nav > * {
  animation: animate-quick-nav-link-in var(--time-1) var(--easing-decelerate) forwards;
  opacity: 0;
  scale: 0;
}

.quick-nav > :nth-child(2) {
  animation-delay: var(--animation-delay);
}

.quick-nav > :nth-child(3) {
  animation-delay: calc(var(--animation-delay) * 2);
}

.quick-nav > :nth-child(4) {
  animation-delay: calc(var(--animation-delay) * 3);
}

@keyframes animate-quick-nav-in {
  from {
    translate: 0 100%;
  }

  to {
    translate: 0;
  }
}

@keyframes animate-quick-nav-link-in {
  from {
    opacity: 0;
    scale: 0;
  }

  to {
    opacity: 1;
    scale: 1;
  }
}

.quick-nav-link {
  background-color: var(--color-theme);
  color: var(--color-ground);
  padding: var(--space-1) var(--space-2);
  transition-property: background-color;
}

@media (hover: hover) {
  .quick-nav-link:hover {
    background-color: var(--color-theme-less);
    color: var(--color-ground);
  }
}

.quick-nav-link:active {
  color: var(--color-ground);
}

.quick-nav-link--active {
  background-color: var(--color-highlight-default) !important;
}

.radio-button {
  --color: var(--color-theme);
  margin-left: calc(-1 * var(--border-width-2));
  flex-grow: 1;
  display: inline-flex;
  position: relative;
}

.radio-button__input {
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.radio-button__input:checked + .radio-button__appearance {
  z-index: var(--z-index-1);
}

.radio-button__appearance {
  background-color: var(--color-ground-more);
  cursor: pointer;
  width: 100%;
  transition-property: border-color, color, filter;
}

@media (hover: hover) {
  .radio-button__appearance:hover {
    border-color: var(--color);
    color: var(--color);
    filter: brightness(1.2) saturate(1.2);
    z-index: var(--z-index-2);
  }
}

.radio-button:first-child .radio-button__appearance {
  border-bottom-left-radius: var(--border-radius-1);
  border-top-left-radius: var(--border-radius-1);
}

.radio-button:last-child .radio-button__appearance {
  border-bottom-right-radius: var(--border-radius-1);
  border-top-right-radius: var(--border-radius-1);
}

.radio-button__input:focus-visible ~ .radio-button__appearance {
  filter: brightness(1.2) saturate(1.2);
}

.radio-button__input:active ~ .radio-button__appearance {
  filter: brightness(.8) saturate(1.2);
}

.radio-button-group {
  border: none;
}

.radio-button-group[aria-invalid="true"] .radio-button__appearance {
  color: var(--color-negative);
  border-color: var(--color-negative);
}

.radio-button__input:checked ~ .radio-button__appearance {
  background: var(--color);
  border-color: var(--color);
  color: var(--color-ground);
}

@media (hover: hover) {
  .radio-button__input:checked ~ .radio-button__appearance:hover {
    filter: brightness(1.2) saturate(1.2);
  }
}

.radio-button__input:active:checked ~ .radio-button__appearance {
  filter: brightness(.8) saturate(1.2);
}

.radio-button__input:checked:focus-visible ~ .radio-button__appearance {
  filter: brightness(1.2) saturate(1.2);
}

.radio-button-group__container {
  display: flex;
}

.select__icon {
  pointer-events: none;
  inset-block: 0;
  right: var(--space-2);
  align-items: center;
  transition-property: color;
  display: flex;
  position: absolute;
}

.select__select {
  appearance: none;
  cursor: pointer;
  width: 100%;
  padding-right: 2.5em !important;
}

.select__select::-moz-focus-inner {
  border: none;
}

.select__select-container {
  position: relative;
}

option {
  overflow-inline: hidden;
  padding: var(--spacing-1) var(--spacing-2);
  text-overflow: ellipsis;
}

.select__select:focus ~ .select__icon {
  color: var(--color-theme-less);
}

@media (hover: hover) {
  .select__select:hover ~ .select__icon {
    color: var(--color-theme-less);
  }
}

.select__select[aria-invalid="true"] ~ .select__icon {
  color: var(--color-negative);
}

.spinner {
  --color: var(--color-theme);
  --diameter: var(--space-4);
  animation: var(--animation-fade-in), var(--animation-spin);
  border-color: var(--color) transparent var(--color) transparent;
  border-style: solid;
  border-width: calc(var(--diameter) / 6);
  box-sizing: border-box;
  height: var(--diameter);
  margin: var(--diameter) auto;
  width: var(--diameter);
  display: flex;
}

.spinner--inline {
  --color: currentColor;
  --diameter: 1em;
  margin: auto;
  display: inline-flex;
}

.spinner--margin-false {
  margin-block: 0;
}

.spinner--margin-start {
  margin-inline-start: var(--space-0);
}

.spinner--margin-end {
  margin-inline-end: var(--space-0);
}

.sub-heading {
  display: block;
}

.toggle {
  --toggle-button-diameter: var(--space-2);
  --toggle-width: calc(2 * var(--toggle-button-diameter));
  position: relative;
}

.toggle__appearance {
  background: var(--color-balance);
  height: var(--toggle-button-diameter);
  padding: var(--border-width-2);
  width: var(--toggle-width);
  border-color: #0000;
  flex-shrink: 0;
  transition-property: background-color, border-color, filter;
}

.toggle__appearance:after {
  background: var(--color-ground);
  content: "";
  height: var(--toggle-button-diameter);
  width: var(--toggle-button-diameter);
  border-radius: 50%;
  transition-property: translate;
  display: block;
}

.toggle__input {
  opacity: 0;
  pointer-events: none;
  height: calc(var(--toggle-button-diameter)  + 2 * var(--border-width-2));
  width: calc(var(--toggle-width)  + 2 * var(--border-width-2));
  position: absolute;
}

.toggle__input:checked ~ .toggle__appearance {
  background: var(--color-positive);
}

.toggle__input:checked ~ .toggle__appearance:after {
  translate: calc(var(--toggle-width)  - var(--toggle-button-diameter));
}

.toggle__input:focus ~ .toggle__appearance {
  border-color: var(--color-theme-less);
}

.toggle__input[aria-invalid="true"]:not(:focus-visible) ~ .toggle__appearance {
  border-color: var(--color-negative);
}

.toggle__input:disabled ~ .toggle__appearance {
  background: var(--color-balance);
}

.toggle__input:disabled ~ .toggle__appearance:after {
  background: var(--color-ground-less);
}

@media (hover: hover) {
  .toggle__input:hover:not(:disabled) ~ .toggle__appearance {
    background: var(--color-balance);
  }

  .toggle__input:checked:hover:not(:disabled) ~ .toggle__appearance {
    background: var(--color-positive);
    filter: brightness(1.1);
  }
}

.toggle__label {
  column-gap: var(--space-2);
  cursor: pointer;
  grid-template-columns: auto 1fr;
  display: grid;
}

.toggle__label--disabled {
  cursor: not-allowed;
}

.toggle__label-copy {
  align-items: center;
  display: flex;
}

.word-cloud__word {
  animation-name: word-slide;
  animation-duration: var(--time-2);
  animation-timing-function: var(--easing-standard);
}

.word-cloud__word--clickable {
  cursor: pointer;
}

@keyframes word-slide {
  from {
    translate: var(--word-from-x) var(--word-from-y);
  }
}

.word-cloud__word-container:nth-child(6n+1) {
  fill: var(--color-highlight-0);
}

.word-cloud__word-container:nth-child(6n+2) {
  fill: var(--color-highlight-1);
}

.word-cloud__word-container:nth-child(6n+3) {
  fill: var(--color-highlight-2);
}

.word-cloud__word-container:nth-child(6n+4) {
  fill: var(--color-highlight-3);
}

.word-cloud__word-container:nth-child(6n+5) {
  fill: var(--color-highlight-4);
}

.word-cloud__word-container:nth-child(6n+6) {
  fill: var(--color-highlight-5);
}

.w-nav__header {
  margin: var(--space-3) var(--space-3) 0;
}

.w-nav__sign-out {
  margin-block: var(--space-2);
}

.w-nav__sync-state {
  margin: var(--space-3) var(--space-3) calc(-1 * var(--space-4));
}

@media (width >= 66rem) {
  .w-nav__sync-state {
    margin-bottom: calc(-1 * var(--space-3));
  }
}

.w-profile {
  grid-column-gap: var(--space-2);
  grid-template-columns: 2.5em 1fr;
  display: grid;
}

.w-profile > .w-icon {
  grid-row-end: span 2;
}

.w-profile__email {
  font-size: var(--font-size-0);
  text-overflow: ellipsis;
  overflow: hidden;
}

.w-profile__state {
  align-self: flex-end;
}
/*# sourceMappingURL=client.b0620f41.css.map */
